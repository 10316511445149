<template>
  <div>
    <v-card class="mb-6">
      <v-card-text>
        <v-row class="align-center">
          <v-col md="4">
            <h2>Promosi</h2>
            <small>Halaman untuk menambahkan promosi di aplikasi</small>
          </v-col>
          <v-col md="8">
            <v-row class="justify-end">
              <v-col md="6">
                <v-text-field
                  v-model="search"
                  :append-icon="icons.mdiMagnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <v-btn
                  color="primary"
                  large
                  width="100%"
                  @click="showFormAdd"
                >
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                  Tambah Data
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      v-for="promotion,index in promotions"
      :key="index"
      class="overflow-hidden mb-4"
    >
      <v-row>
        <v-col
          md="2"
          class="pa-0"
        >
          <v-img
            :src="promotion.image"
            height="100%"
          ></v-img>
        </v-col>
        <v-col md="10">
          <v-card-title>
            {{ promotion.name }}
            <v-spacer></v-spacer>
            <v-menu
              offset-y
              nudge-bottom="5"
              nudge-left="60"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="black"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="20">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="showFormEdit(promotion.uuid)">
                  <v-list-item-title>
                    <div class="d-flex">
                      <div>
                        <v-img
                          width="16"
                          src="@/assets/icons/edit.svg"
                        ></v-img>
                      </div>

                      <small class="ml-2">Edit</small>
                    </div>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="confirmDestroy(promotion.uuid)">
                  <v-list-item-title>
                    <div class="d-flex">
                      <div>
                        <v-img
                          width="16"
                          src="@/assets/icons/trash-red.svg"
                        ></v-img>
                      </div>

                      <small class="ml-2 error--text">Hapus</small>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text class="">
            <p v-html="promotion.description"></p>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <ModalDialog
      :visible="modalDialog"
      :is-loading-button="isLoadingButton"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Promosi': 'Edit Promosi'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-text-field
            v-model="promotion.name"
            label="Nama"
            outlined
            dense
          >
          </v-text-field>
        </div>
        <div class="mb-4">
          <FileInput
            v-model="initialFile"
            :value="promotion.image"
            :prependicon="icons.mdiImageMultipleOutline"
            label="Gambar Promosi"
            @input="getFile"
          ></FileInput>
        </div>
        <div>
          <CKEditor
            v-model="promotion.description"
            :value="promotion.description"
            label="Content"
            outlined
            dense
          ></CKEditor>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import { mdiPlus, mdiMagnify, mdiDotsVertical, mdiImageMultipleOutline } from '@mdi/js'
import ModalDialog from '@/views/components/ModalDialog.vue'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import FileInput from '@/views/components/FileInput.vue'
import CKEditor from '@/views/components/CkEditor.vue'

export default {
  name: 'Promotion',
  components: {
    ModalDialog,
    ConfirmDialog,
    FileInput,
    CKEditor,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiDotsVertical,
        mdiImageMultipleOutline,
      },
      service: 'promotion',
      search: '',
      dialog: '',
      promotions: [],
      promotion: {},
      modalDialog: false,
      confirmDialog: false,
      isLoadingButton: false,
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.promotion.image === 'string') {
          return new File([this.promotion.image], this.promotion.image, { type: 'text/plain' })
        }

        return this.promotion.image
      },
      set() {
        return this.promotion.image
      },
    },
  },
  watch: {
    search: {
      handler() {
        if (this.search.length > 3 || this.search.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.page = 1
            this.listPromotion()
          }, 500)
        }
      },
    },
  },
  mounted() {
    this.listPromotion()
  },
  methods: {
    async listPromotion(params = {}) {
      await this.$services.ApiServices.list(this.service, { ...params, search: this.search }).then(
        ({ data }) => {
          this.promotions = data.data
        },
        err => {
          console.error(err)
        },
      )
    },
    showFormAdd() {
      this.dialog = 'add'
      this.modalDialog = true
    },
    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('name', this.promotion.name)
      this.formData.append('image', this.promotion.image)
      this.formData.append('description', this.promotion.description)
      await this.$services.ApiServices.add(this.service, this.formData).then(
        async ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          await this.listPromotion()
        },
        err => {
          console.error(err)
          this.isLoadingButton = true
        },
      )
      this.modalDialog = false
      this.isLoadingButton = false
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      this.promotion.uuid = uuid
      await this.$services.ApiServices.get(this.service, this.promotion.uuid).then(
        ({ data }) => {
          this.promotion = data.data
        },
        err => {
          console.error(err)
        },
      )
    },
    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('name', this.promotion.name)
      this.formData.append('image', this.promotion.image)
      if (typeof this.promotion.image === 'string' || this.promotion.image === null) {
        this.formData.delete('image')
      }
      this.formData.append('description', this.promotion.description)
      await this.$services.ApiServices.update(this.service, this.formData, this.promotion.uuid).then(
        async ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          await this.listPromotion()
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      this.modalDialog = false
      this.isLoadingButton = false
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      console.log(uuid)
      this.promotion.uuid = uuid
      this.confirmDialog = true
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.promotion.uuid).then(
        async ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          await this.listPromotion()
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      this.confirmDialog = false
      this.isLoadingButton = false
    },
    getFile(data) {
      this.promotion.image = data
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
